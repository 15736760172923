import Enum from '@/utils/Enum';

const CustomerStatusEnumList = [
  {
    value: 'EFFECTIVE',
    name: '有效 ',
  },
  {
    value: 'INVALID',
    name: '无效',
  },
  {
    value: 'DRAFT',
    name: '草稿',
  },
];

export default new Enum(CustomerStatusEnumList);
export { CustomerStatusEnumList };
