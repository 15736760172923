<template>
  <div>
    <div class="content_block">
      <query-form
        label-width="100px"
        @search="handleSearch"
        @reset="handleReset"
        :keepOpenedRow="2"
      >
        <el-form-item label="医院状态：" :span="24">
          <check-tag v-model="query.status" @change="handleSearch">
            <check-tag-item
              :label="i.name"
              :value="i.value"
              :key="i.value"
              v-for="i in CustomerStatusEnumList"
            ></check-tag-item>
          </check-tag>
        </el-form-item>
        <el-form-item label="医院名称：">
          <el-input
            v-model="query.name"
            placeholder="请输入医院名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="创建人：">
          <el-input
            v-model="query.createUserName"
            placeholder="请输入创建人"
          ></el-input>
        </el-form-item>
      </query-form>
    </div>
    <table-with-pagination
      :tableData="customerList"
      class="content_block"
      :filterConfig="{ remote: true }"
      :columnList="columnList"
      :pagination.sync="pagination"
      :sortInfo.sync="createTimeSortInfo"
      @current-change="getTableList"
      @sort-change="getTableList"
      @filter-change="handleFilterChange"
      :leftToolbar="tableButtons"
    />
  </div>
</template>
<script>
import * as dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import CustomerApi from '@/api/customer';
import { wrapWithAll } from '@/utils/Enum';
import CustomerStatusEnum, {
  CustomerStatusEnumList,
} from '@/enums/CustomerStatusEnum';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';

export default {
  name: 'CUSTOMER_INDEX',
  mixins: [TablePaginationMixin],
  computed: {
    ...mapGetters(['actionPermissionMap']),
    columnList () {
      return [
        {
          tooltip: true,
          label: '医院名称',
          minWidth: '160px',
          prop: 'name',
          render: (h, { row }) => {
            const isPermission
              = this.actionPermissionMap
              && this.actionPermissionMap['customer:view'];
            let clickFn = isPermission
              ? this.handleActionClick.bind(this, row, 'detail')
              : () => {};
            let classObj = {
              'text-primary': isPermission,
              'cursor-pointer': isPermission,
            };

            return (
              <span class={classObj} onClick={clickFn}>
                {row.name}
              </span>
            );
          },
        },
        {
          tooltip: true,
          label: '统一社会信用代码',
          minWidth: '180px',
          prop: 'socialCode',
        },
        {
          tooltip: true,
          label: '到期时间',
          minWidth: '150px',
          prop: 'indate',
          format: this.$options.filters['date-format'],
          sortable: true,
        },
        {
          tooltip: true,
          label: '支付状态',
          minWidth: '150px',
          prop: 'paymentStatus',
          format: (val) =>
            val === 'ACCOUNT_PAID'
              ? '已付款'
              : val === 'NON_PAYMENT'
                ? '未付款'
                : val === 'UNDER_REVIEW'
                  ? '审核中'
                  : val === 'AUDIT_FAILED'
                    ? '审核不通过'
                    : '--',
          filters: [
            { label: '已付款', value: 'ACCOUNT_PAID' },
            { label: '未付款', value: 'NON_PAYMENT' },
            { label: '审核中', value: 'UNDER_REVIEW' },
            { label: '审核不通过', value: 'AUDIT_FAILED' },
          ],
          filterMultiple: false,
        },
        {
          tooltip: true,
          label: '医院状态',
          minWidth: '150px',
          prop: 'status',
          format: (val) => CustomerStatusEnum[val],
          filters: [
            {
              label: '有效',
              value: 'EFFECTIVE',
              checked: ['EFFECTIVE'].includes(this.query.status),
            },
            {
              label: '无效',
              value: 'INVALID',
              checked: ['INVALID'].includes(this.query.status),
            },
            {
              label: '草稿',
              value: 'DRAFT',
              checked: ['DRAFT'].includes(this.query.status),
            },
          ],
          filterMultiple: false,
        },
        {
          tooltip: true,
          label: '创建人',
          minWidth: '150px',
          prop: 'createUserName',
        },
        {
          tooltip: true,
          label: '创建时间',
          minWidth: '150px',
          prop: 'createTime',
          sortable: true,
          render: (h, { row }) => {
            return h('span', this.timeFormat(row.createTime));
          },
        },
        {
          tooltip: true,
          label: '所在地区',
          minWidth: '150px',
          prop: 'areaName',
          render: (h, { row }) => {
            return (
              <span>
                {row.provinceName || ''}
                {row.cityName || ''}
                {row.areaName || ''}
              </span>
            );
          },
        },
        {
          tooltip: true,
          label: '医院地址',
          minWidth: '150px',
          prop: 'address',
        },
        {
          label: '操作',
          minWidth: '180px',
          fixed: 'right',
          render: (h, { row }) => {
            let actions = [];
            if (
              (!row.indate
              || dayjs(new Date()).add(30, 'day') >= new Date(row.indate)) && (row.paymentStatus !== 'UNDER_REVIEW')
            ) {
              actions.push(
                <span
                  class="cursor-pointer text-primary ml-1 "
                  onClick={this.handleActionClick.bind(this, row, 'pay')}
                >
                  支付
                </span>,
              );
            }
            if (row.canDelete) {
              actions.push(
                <span
                  v-auth="customer:edit"
                  class="cursor-pointer text-primary ml-1 "
                  onClick={this.handleActionClick.bind(this, row, 'edit')}
                >
                  编辑
                </span>,
              );
              actions.push(
                <span
                  v-auth="customer:delete"
                  class="cursor-pointer text-primary ml-1 table-action table-action-delete "
                  onClick={this.handleActionClick.bind(this, row, 'delete')}
                >
                  删除
                </span>,
              );
            }
            return <div>{...actions}</div>;
          },
        },
      ];
    },
  },
  data () {
    return {
      CustomerStatusEnumList: wrapWithAll(CustomerStatusEnumList),
      query: {
        status: null,
        name: '',
        createUserName: '',
        paymentStatus: null,
      },
      customerList: [],
      type: '',
      tableButtons: [
        {
          name: '新增', // 按钮内容(必填)
          status: 'primary', // 按钮样式
          size: 'small', // 大小尺寸
          permission: 'customer:add',
          click: () => {
            this.$router.push({
              path: '/customer/add',
              query: {
                type: 'add',
              },
            });
          },
        },
      ],
    };
  },
  methods: {
    handleFilterChange (params) {
      this.pagination.currentPage = 1;
      this.query[params.property] = params.values.length
        ? params.values.join(',')
        : null;
      this.handleSearch();
    },
    filterStatusMethod ({ value, row }) {
      return value.includes(row.status);
    },
    fixDigit (v) {
      return v < 10 ? `0${v}` : v;
    },
    timeFormat (data) {
      if (data !== 0 && !data) {
        return '';
      }

      let date = new Date(data);
      let year = date.getFullYear();
      let month = this.fixDigit(date.getMonth() + 1);
      let dd = this.fixDigit(date.getDate());
      let hour = this.fixDigit(date.getHours());
      let min = this.fixDigit(date.getMinutes());
      let sec = this.fixDigit(date.getSeconds());

      return `${year}-${month}-${dd} ${hour}:${min}:${sec}`;
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    handleReset () {
      this.query = {
        status: null,
        name: '',
        createUserName: '',
        paymentStatus: null,
      };
      this.handleSearch();
    },
    getTableList () {
      CustomerApi.getCustomerList({
        ...this.query,
        ...this.pagination,
        ...this.createTimeSortInfo,
        pageNum: this.pagination.currentPage,
      }).then((res) => {
        this.customerList = res.body.records;
        this.pagination.total = res.body.total;
      });
    },
    handleActionClick (data, type) {
      if (type === 'delete') {
        this.$confirm('此操作将永久删除医院, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            CustomerApi.deleteCustomer(data.id).then(() => {
              this.$message.success('删除成功');
            });
          })
          .catch(() => {
            this.$message.info('已取消删除');
          });
      } else if (type === 'pay') {
        this.$eventBus.$emit('openPaymentDialogManually', {
          defaultCheckedId: data.id,
          defaultCheckedName: data.name,
        });
      } else {
        let path = type === 'detail' ? '/customer/detail' : type === 'edit' ? '/customer/edit' : '';
        this.$router.push({
          path: path,
          query: {
            type: type,
            id: data.id,
          },
        });
      }
    },
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'CUSTOMER_DETAIL') {
      switch (this.type) {
      case 'detail':
        to.meta.title = '医院档案详情';
        break;
      case 'edit':
        to.meta.title = '医院档案编辑';
        break;
      default:
        break;
      }
    }

    next();
  },
};
</script>
